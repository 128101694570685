import React, { Component } from 'react'
import { Link } from 'gatsby'
import Logo from '../assets/images/Logo.png'

class Footer extends Component {
  render() {
    const { acf } = this.props.data.wp.siteSettings
    return (
      <section className='footer'>
        <div className='footer__content'>
          <div className='footer__inner container'>
            <div className='footer__nav footer__nav--left'>
              <img src={Logo} alt='Campbell'/>
            </div>
              <div className='footer__nav footer__nav--right'>
                <ul className='home1'>
                  <li><Link to='/mutual-expectations'>Mutual Expectations</Link></li>
                  <li><Link to='/owners-committees'>Owners Committees</Link></li>
                  <li><Link to='/developers'>Developers</Link></li>
                </ul>

                <ul className='home2'>
                  <li><Link to='/about'>About Us</Link></li>
                  <li><Link to='/point-of-difference'>Point of Difference</Link></li>
                  <li><Link to='/contact'>Contact Us</Link></li>
                </ul>

                <ul className='details'>
                  <li>{ acf.address1 }</li>
                  <li>{ acf.address2 }</li>
                  <li>{ acf.address3 }</li>
                  <li>© Copyright 2023 Campbell Corporate Services</li>
                </ul>

            </div>
          </div>
        </div>
      </section>
    )
  }
}

Footer.defaultProps = {
  siteTitle: `Campbell`,
}

export default Footer
