import React, { Component } from 'react'
import Logo from '../assets/images/logoclear.svg'
import LogoDark from '../assets/images/logo-dark.svg'
import { Link } from 'gatsby'



class Header extends Component {

  state = {
    scrolled: false,
    menuActive: false,
    subMenuActive: true,
    pageContext: this.props.pageContext || {},
  }

  componentDidMount() {
    document.addEventListener('scroll', this._onScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this._onScroll)
  }

  _onScroll = () => {
    let { scrolled } = this.state
    if (window.scrollY > 120 && !scrolled) this.setState({ scrolled: true })
    if (window.scrollY <= 120 && scrolled) this.setState({ scrolled: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ menuActive: !this.state.menuActive })
    document.querySelector('body').classList.toggle('menu-open')
  }

  _hideOffCanvas = () => {
    this.setState({ menuActive: false })
    //document.querySelector('body').classList.remove('menu-open')
  }

  _toggleOffCanvasSubMenu = () => {
    this.setState({ subMenuActive: !this.state.subMenuActive })
    document.querySelector('body').classList.toggle('offcanvas__sub-menu')
  }

  render() {
    const { menuActive, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas
    }

    let { pathname } = this.props.location
    let darkRoutes = ['/about', '/about/', '/contact', '/contact/', '/point-of-difference', '/point-of-difference/']
    let dark = darkRoutes.includes(pathname)
    if (pathname.match(/^\/about/)) dark = true;
    if (menuActive) dark = false
    let teamMenus = this.props.data.allWpTeam.edges

    return (
      <>
        <header className={`header ${dark ? 'header--dark' : ''} ${scrolled ? 'header--scrolled' : ''}`}>
          <div className="header__inner container">
            <div className="header__nav header__nav--left">
              { dark && <Link to="/"><img src={LogoDark} alt="Campbell" /></Link> }
              { !dark && <Link to="/"><img src={Logo} alt="Campbell" /></Link> }
            </div>
            <div className="header__nav header__nav--right">
              <ul>
                <li><Link to="/about">About Us</Link>
                  <ul className="header__sub-menu">
                    <li><Link to="/about/why-choose-us">Why Choose Us</Link></li>
                    <li><Link to="/about/team">Our Team</Link>
                      <ul className="header__sub-menu header__sub-menu-child">
                        { teamMenus.map((el, i) => ( <li key={i}><Link to={`/about/team/${el.node.slug}/`}>{el.node.title}</Link></li> )) }
                      </ul>
                    </li>
                    <li><Link to="/about/testimonials">Testimonials</Link></li>
                    <li><Link to="/about/loc-group">LOC Group</Link></li>
                  </ul>
                </li>
                <li><Link to="#services">Our Services</Link>
                  <ul className="header__sub-menu autowidth">
                    <li><Link to="/owners-corporation-management">Owners Corporation Management</Link></li>
                    <li><Link to="/mutual-expectations">Your Expectations</Link></li>
                    <li><Link to="/owners-committees">Owners Committees</Link></li>
                    <li><Link to="/developers">New Developments</Link></li>
                  </ul>
                </li>
                <li><Link to="/point-of-difference">Our Difference</Link></li>
                <li><Link to="/news">News</Link></li>
                <li><Link to="https://ccs.helpjuice.com/">Knowledge Base</Link></li>
                <li><Link to="/contact"><button className="btn">Contact Us</button></Link></li>
              </ul>
              <button className={ this.state.menuActive ? 'hamburger active' : 'hamburger' } onClick={this._toggleOffCanvas}>
                <span className="lines"></span>
              </button>
            </div>
          </div>
        </header>
        <div className={`${menuActive ? 'off-canvas active' : 'off-canvas'}`}>
        <nav className="off-canvas__nav">
          <ul>
          <li><Link to="/about" {...props}>About Us</Link></li>
          <li className={this.state.subMenuActive ? 'subMenu active' : 'subMenu' } onClick={this._toggleOffCanvasSubMenu}><Link to="/mutual-expectations">Our Services</Link>
            <ul className="offcanvas__submenu">
              <li><Link to="/mutual-expectations">Mutual Expectations</Link></li>
              <li><Link to="/owners-committees">Owners Committees</Link></li>
              <li><Link to="/developers">Developers</Link></li>
            </ul>
          </li>
          <li><Link to="/" {...props}>My Account</Link></li>
          <li><Link to="/point-of-difference" {...props}>Point of Difference</Link></li>
          <li><Link to="/contact" {...props}>Contact Us</Link></li>
          </ul>
        </nav>
      </div>
      </>
    )
  }
}



Header.defaultProps = {
  siteTitle: `Campbell`,
}

export default Header
